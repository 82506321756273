import React from 'react'

import Carousel from 'react-multi-carousel'
import Layout from '../components/Layout'
import Seo from '../components/seo'

import Hero from '../components/Hero'
import SliderDebates from '../components/SliderDebates'
import DebatesBox from '../components/DebatesBox'
import 'react-multi-carousel/lib/styles.css'

import debatesBoxImg from '../images/blm-protests.png'

import '../scss/page-debates.scss'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const coursesPage = () => (
  <Layout>
    <Seo title="Debates | Talk of the Town" />

    <Hero text="Debates" />

    <div className="categories">
      <SliderDebates sectionTitle="Artes">
        <Carousel
          swipeable={false}
          draggable={false}
          arrows
          showDots
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlaySpeed={1000}
          keyBoardControl
          containerClass="carousel-container row"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={3}
        >
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="Greta Thumberg"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="21/06/20 - 16h30"
            title="Da vinci exhibition in paris"
            description="Lorem ipsum dolor sit amet dolor consectetur adisplicing."
            buttonUrl="/register/"
            buttonText="Inscreva"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
        </Carousel>
      </SliderDebates>

      <SliderDebates sectionTitle="Atualidades">
        <Carousel
          swipeable={false}
          draggable={false}
          arrows
          showDots
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlaySpeed={1000}
          keyBoardControl
          containerClass="carousel-container row"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={3}
        >
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="Greta Thumberg"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="21/06/20 - 16h30"
            title="Da vinci exhibition in paris"
            description="Lorem ipsum dolor sit amet dolor consectetur adisplicing."
            buttonUrl="/register/"
            buttonText="Inscreva"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
        </Carousel>
      </SliderDebates>

      <SliderDebates sectionTitle="Tecnologia">
        <Carousel
          swipeable={false}
          draggable={false}
          arrows
          showDots
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlaySpeed={1000}
          keyBoardControl
          containerClass="carousel-container row"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={3}
        >
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="Greta Thumberg"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="21/06/20 - 16h30"
            title="Da vinci exhibition in paris"
            description="Lorem ipsum dolor sit amet dolor consectetur adisplicing."
            buttonUrl="/register/"
            buttonText="Inscreva"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
        </Carousel>
      </SliderDebates>

      <SliderDebates sectionTitle="Música">
        <Carousel
          swipeable={false}
          draggable={false}
          arrows
          showDots
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlaySpeed={1000}
          keyBoardControl
          containerClass="carousel-container row"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          slidesToSlide={3}
        >
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="Greta Thumberg"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="21/06/20 - 16h30"
            title="Da vinci exhibition in paris"
            description="Lorem ipsum dolor sit amet dolor consectetur adisplicing."
            buttonUrl="/register/"
            buttonText="Inscreva"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
          <DebatesBox
            widthClass="col-12"
            background={debatesBoxImg}
            dateHour="20/06/20 - 16h30"
            title="The 3d printer heart"
            description="O assassinato de George Floyd, um homem negro dos Estados Unidos, por um policial, durante uma abordagem, gerou uma série de manifestações por todo o país."
            buttonUrl="/register/"
            buttonText="Inscreva-se"
          />
        </Carousel>
      </SliderDebates>
    </div>
  </Layout>
)

export default coursesPage
